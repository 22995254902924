//去掉多余的children
function getTreeData(data) {
    for (let i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
            data[i].children = undefined;
        } else {
            this.getTreeData(data[i].children);
        }
    }
    return data;
}

//重复校验
function getArrDifference(array1, array2, type) {
    let result = [];
    for (let i = 0; i < array1.length; i++) {
        let isExist = false;
        for (let j = 0; j < array2.length; j++) {
            if (array1[i][type] === array2[j][type]) {
                isExist = true;
                break;
            }
        }
        if (!isExist) {
            result.push(array1[i]);
        }
    }
    return result
}

//减法
function numSub(arg1, arg2) {
    let num = 0
    let a1 = parseFloat(arg1) * 1000
    let a2 = parseFloat(arg2) * 1000
    num = a1 - a2
    num = num / 10
    num = parseFloat(num)
    num = Math.floor(num) / 100;
    return num
}

//加法
function numAdd(ar1, ar2) {
    let arg1 = ar1 ? ar1 : 0
    let arg2 = ar2 ? ar2 : 0
    let num = 0
    let a1 = parseFloat(arg1) * 1000
    let a2 = parseFloat(arg2) * 1000
    num = a1 + a2
    num = num / 10
    num = parseFloat(num)
    num = Math.floor(num) / 100;
    return num
}

//乘法
function numMulti(arg1, arg2) {
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length
    } catch (e) { }
    try {
        m += s2.split(".")[1].length
    } catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

function onGeshi(arr) {
    let list = arr
    if (list.length > 0) {
        list = JSON.stringify(list)
    } else {
        list = ''
    }
    return list
}

//今天
function getNewDate(ismyYear) {
    let myDate = new Date()
    let myYear = myDate.getFullYear();
    if (ismyYear) {
        myYear = myYear + ismyYear
    }
    let myMonth = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
    let myDay = myDate.getDate()
    let today = myYear + '-' + myMonth + '-' + myDay
    return today
}
//本月第一天
function getFirstDayOfCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以需要+1，并且补0
    const day = '01'; // 当月第一天

    return `${year}-${month}-${day}`;
}

function getNewDateAll() {
    let myDate = new Date()
    let myYear = myDate.getFullYear();
    let myMonth = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
    let myDay = myDate.getDate()<10?'0'+myDate.getDate():myDate.getDate()
    let myHours = myDate.getHours()
    let myMinutes = myDate.getMinutes()
    let mySeconds = myDate.getSeconds()
    let today = myYear + '-' + myMonth + '-' + myDay + ' ' + myHours + ':' + myMinutes + ':' + mySeconds
    return today
}

//年月日
function getDateGe(myDate) {
    var myDate1=new  Date(myDate)
    let myYear = myDate1.getFullYear();
    let myMonth = myDate1.getMonth() + 1 < 10 ? '0' + (myDate1.getMonth() + 1) : myDate1.getMonth() + 1
    let myDay = myDate1.getDate()<10?'0'+myDate1.getDate():myDate1.getDate()
    let today = myYear + '-' + myMonth + '-' + myDay
    return today
}
//年.月.日
function getDateGe2(myDate) {
    var myDate1=new  Date(myDate)
    let myYear = myDate1.getFullYear();
    let myMonth = myDate1.getMonth() + 1 < 10 ? '0' + (myDate1.getMonth() + 1) : myDate1.getMonth() + 1
    let myDay = myDate1.getDate()<10?'0'+myDate1.getDate():myDate1.getDate()
    let today = myYear + '.' + myMonth + '.' + myDay
    return today
}

/* 清除数据 */
function clearForm(obj, init) {
    Object.keys(obj).forEach(key => {
        obj[key] = init[key]
    })
}

/** 格式化 */
function formtData(obj) {
    let _obj = {}
    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
            _obj[key] = obj[key][0]
        } else {
            _obj[key] = obj[key]
        }
    })
    return _obj;
}

/** 互换位置 */
function swap(arr, index1, index2) {
    let _arr = JSON.parse(JSON.stringify(arr))
    let temp = _arr[index1];
    _arr[index1] = _arr[index2];
    _arr[index2] = temp;
    return _arr;
}

function timeHandle(timer) {
    if (!timer) return new Object
    const [state_time, end_time] = timer
    return {
        state_time,
        end_time,
    }
}

function delTagList(url) {
    if (!timer) return new Object
    const [state_time, end_time] = timer
    return {
        state_time,
        end_time,
    }
}

//字母大小写转换方法
function strChange(arg) {
    var str=arg.split('');
    for(var i = 0; i < str.length; i++) {
        if (str[i].charAt() >= "a" && str[i].charAt() <= "z") {
            str[i] = str[i].toUpperCase();

        } else {
            // str[i] = str[i].toLowerCase();
        }
    }
    return str.join('');
}

module.exports = {
    getTreeData,
    getArrDifference,
    numSub,
    numAdd,
    numMulti,
    onGeshi,
    getNewDate,
    getFirstDayOfCurrentMonth,
    getDateGe,
    getDateGe2,
    clearForm,
    formtData,
    swap,
    timeHandle,
    delTagList,
    getNewDateAll,
    strChange
}