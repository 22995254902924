import router from './router'
import store from './store'

router.beforeEach(async (to, from, next) => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    let userinfo = params.get('userinfo');
    let store_id = params.get('store_id');
    let shop_code = params.get('shop_code');
    let sourceType = params.get('sourceType');
    if(userinfo){
        localStorage.setItem("userinfo", userinfo);
        localStorage.setItem("token",JSON.parse(userinfo).token);
    }
    if (store_id){
        localStorage.setItem("store_id", store_id);
    }
    if (shop_code){
        localStorage.setItem("shop_code", shop_code);
    }
    if (sourceType){
        localStorage.setItem("sourceType", sourceType);
    }

    let hasToken = localStorage.getItem("token");
    if (hasToken) {
        var initial_menu = store.getters.initial_menu  //初始菜单
        var roles_list = store.getters.roles_list   //当前登录用户的权限

        if (initial_menu.length > 0 && roles_list.length > 0) {
            next()
        } else if (to.path === '/401') {
            next()
        } else if (to.path === '/402') {
            next()
        } else {
            try {
                let onRoles = new Promise((resolved, rejected) => {
                    if (roles_list.length <= 0) {
                        store.dispatch('onRoles').then(res => {   //查询当前用户的角色列表
                            if (res == 'false') {
                                next('/401')
                            } else {
                                roles_list = res
                                resolved(true)
                            }
                        })
                    } else {
                        resolved(true)
                    }
                })

                let onMenu = new Promise((resolved, rejected) => {
                    if (initial_menu.length <= 0) {
                        store.dispatch('onMenu').then(res => {
                            if (res == 'false') {
                                next('/402')
                            } else {
                                initial_menu = res
                                resolved(true)
                            }
                        })
                    } else {
                        resolved(true)
                    }
                })

                Promise.all([onRoles, onMenu]).then((result) => {
                    const NRoutes = {
                        roles: roles_list, menuList: initial_menu
                    }
                    store.dispatch('generateRoutes', NRoutes).then(routerList => {
                        routerList.forEach(res => {
                            router.addRoute('Home', res);
                        })
                        router.addRoute('Home',
                            {
                                name: '404',
                                path: '*',
                                meta: { name: '404' },
                                component: () => import('@/pages/home/404.vue')
                            });
                        next({ ...to, replace: true })
                    })
                })
            } catch (error) {
                next('/login')
            }
        }
    } else {
        if (to.path.startsWith('/link/')){
            next()
            return
        }
        if (to.path==='/callback'){
            next()
            return
        }
        if (to.path === '/login') {
            next()
        } else {
            next('/login')
        }
    }
})
// router.afterEach(() => {})
