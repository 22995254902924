<template>
        <!-- :height="height" -->
    <div class="comm-table" :style="{height: `${height}px`}" ref="table">
        <el-table
        ref="tableinp"
        :data="tableData"
        size="medium"
        stripe
        sortable
        :height="300"
        :page-size="pagesize"
        :header-cell-style="{color: '#68718c', backgroundColor: '#e4e8f0'}"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        v-bind="$attrs"
        v-on="$listeners"
        >
            <el-table-column
            v-if="checked"
            type="selection"
            width="50">
            </el-table-column>

            <el-table-column
            v-for="item in tableFields"
            :key="item.id"
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item?.width ?? 'auto'"
            v-bind="item.componentProps"
            >
                <template v-slot="{ row }">
                    <slot :name="item.prop" :row="row">
                        {{readDeep(row, item.prop, item?.suffix) ?? '--'}}
                    </slot>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
        v-if="total > 0"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        style="margin-top: 15px; flex-shrink: 0;"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    components: {
        
    },
    name: 'Table',
    props: {
        tableData: {
            type: Array,
            default: []
        },
        tableFields: {
            type: Array,
            default: () => []
        },
        total: {
            type: Number,
            default: 0
        },
        pagesize: {
            type: Number,
            default: 10
        },
        checked: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return{
            height: 200,
        }
    },
    methods: {
        readDeep(obj, key, suffix){
            return key.split('.').reduce((a, b) => a && a[b], obj) + (suffix || '')
        },
        handleCurrentChange(v){
            this.$emit('handleCurrentChange', v)
        },
    },
    mounted(){

        const inp = this.$refs.tableinp;
        let instanceMethods = Object.keys(inp).filter((key) => typeof inp[key] === 'function').map((methodName) => ({ [methodName]: inp[methodName].bind(null) }));
        instanceMethods.forEach((item) => {
            Object.entries(item).forEach(([key, value]) => {
                this[key] = value;
            })
        })
        const dom = this.$refs.table
        const rect = dom.getBoundingClientRect();
        this.$nextTick(()=>{
            this.height = window.innerHeight - rect.top - 30;
        })
    },
}
</script>


<style lang="scss" scoped>
    .comm-table{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
</style>