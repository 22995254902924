<template>
    <el-input-number :placeholder="placeholder" v-model="num" @change="handleChange" :min="1" :max="10000"></el-input-number>
</template>
<script>
export default {
    name: 'InputNumber',
    props: {
        value: {
            type: Number,
            default: 1
        },
        placeholder: {
            type: String,
            default: '请输入'
        }
    },
    data() {
        return {
            
        };
    },
    methods: {
        handleChange(value) {

        }
    },
    computed: {
        num: {
            get(){
                return this.$props.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }
};
</script>