class Config {

}

Config.http_static = ''; // 图片上传地址 正式使用
Config.http_url = window.global_config.BASE_URL
Config.http_domain =  window.global_config.BASE_IMG_URL

//接口
Config.http_api = {
    salesTargetList:'son/target/salesTargetList',//获取目标计划
    saveSalesTarget:'son/target/saveSalesTarget',//保存目标计划
    captcha:'son/user/captcha',//获取图片验证码
    login_store:'son/user/login_store', //选择门店登录
    getStoreByMobile:'son/user/getStoreByMobile', //根据手机获取门店列表
    wxLoginNotice:'son/user/wxlogin_notice', //微信回调
    bindWX:'son/user/bind_wx', //绑定微信
    loginMobile: 'son/user/mobilePassword', //登录
    homeindex: 'son/index/index', //首页
    quickLinkList:'son/user/quickLinkList', //获取菜单列表
    addQuickLink:'son/user/addQuickLink', //添加快捷菜单
    removeQuickLink:'son/user/removeQuickLink',//删除快捷菜单
    upload: 'api/common/upload', //图片上传
    getArea: 'api/common/getArea', //获取省市区
    dictionaryList: 'api/common/dictionaryList', //获取字典列表
    getShopByCode: 'son/user/getShopByCode', //登录时根据编码获取门店列表
    getShopConfig: 'son/shop_config/getShopConfig', //获取该门店参数
    setShopConfig: 'son/shop_config/setShopConfig', //设置门店参数
    saveSons: 'son/shop_config/saveSons', //门店参数同步下属门店
    setPrivacyAgreement: 'api/common/setPrivacyAgreement', //设置隐私协议
    getPrivacyAgreement: 'api/common/getPrivacyAgreement', //获取隐私协议
    InstallSite: 'api/Common/InstallSite', //获取图片
    userDetails: 'son/user/userDetails', //用户详情
    NewsReadCount: 'son/News/NewsReadCount', //未阅读消息
    NewsList: 'son/News/NewsList', //消息列表
    NewsReadWhole: 'son/News/NewsReadWhole', //全部阅读
    WxXcxQrcode: 'son/News/WxXcxQrcode', //二维码
    getServerDate:'son/index/getServerDate',//获取服务器时间
    memberTodayBirthdayList:'son/index/memberTodayBirthdayList',//会员生日提醒列表
    reviewList:'son/index/reviewList',//待审核列表
    reviewCount:'son/index/reviewCount',//待审核数量
    memberNeedVisit:'son/index/memberNeedVisit',//需要回访的顾客列表
    planToShop:'son/index/planToShop',//计划到店列表
    staffAbnormal:'son/index/staffAbnormal',//员工到职异常
    rightsEnd:'son/index/rightsEnd',//卡项到期会员
    memberToBeList:'son/index/memberToBeList',//体验转化会员
    giveReviewPass:'son/review/giveReviewPass',//储值赠送审核
    giveReviewNotPass:'son/review/giveReviewNotPass',//储值赠送驳回
    storeSalesTarget:'son/target/storeSalesTarget',//总部业绩达成率
    refreshIndexOrder:'son/index/refreshIndexOrder',//首页刷新订单
    
    listRule: 'son/rule/listRule', //角色列表
    addRule: 'son/rule/addRule', //新增角色
    delRule: 'son/rule/delRule', //删除角色
    editRule: 'son/rule/editRule', //编辑角色
    editStatus: 'son/rule/editStatus', //修改状态

    userList: 'son/user/userList', //用户列表
    addUser: 'son/user/userAdd', //用户新增
    editUser: 'son/user/userEdit', //用户编辑
    ueditStatus: 'son/user/editStatus', //修改状态
    quickSort:'son/user/quickSort', //菜单列表排序
    unbind_wx:'son/user/unbind_wx',//解绑微信

    listWarehouse: 'son/shop_seting/listWarehouse', //仓库列表
    listShop: 'son/shop_seting/listShop', //门店列表
    simpleShopList: 'son/shop_seting/simpleShopList', //门店列表下拉选择
    addShop: 'son/shop_seting/addShop', //新增门店资料
    editShop: 'son/shop_seting/editShop', //编辑门店资料
    listClassifyType: 'api/command_data/listClassifyType', //常用资料类型列表
    listClassify: 'son/command_data/listClassify', //常用资料列表返回id name
    listAllClassify: 'son/command_data/listAllClassify', //常用资料列表
    addClassify: 'son/command_data/addClassify', //新增常用资料
    editClassify: 'son/command_data/editClassify', //编辑常用资料
    delClassify: 'son/command_data/delClassify', //删除常用资料
    distributeCopy: 'son/command_data/distributeCopy', //常用资料分发拷贝
    allEditClassify: 'son/command_data/allEditClassify', //批量保存常用资料

    listProject: 'son/project_seting/listProject',//项目资料列表
    addProject: 'son/project_seting/addProject',//新增项目资料
    editProject: 'son/project_seting/editProject',//编辑项目资料
    peditStatus: 'son/project_seting/editStatus',//编辑项目资料
    fenfaProject: 'son/project_seting/distributeProject',//分发项目
    batchSet:'son/project_seting/batchSet',//项目批量设置


    listProduct: 'son/product_seting/listProduct',//产品资料列表
    paddProject: 'son/product_seting/addProduct',//新增产品资料
    peditProduct: 'son/product_seting/editProduct',//编辑产品资料
    cpeditStatus: 'son/product_seting/editStatus',//修改产品状态
    fenfaProduct: 'son/product_seting/distributeProduct',//分发产品
    productBatchSet:'son/product_seting/batchSet',//产品批量设置

    listCard: 'son/card_seting/listCard',//卡类资料列表
    listCardName: 'son/card_seting/listCardName',//卡类资料列表
    editCard: 'son/card_seting/editCard',//编辑卡类资料
    addCard: 'son/card_seting/addCard',//新增卡类资料
    aeditStatus: 'son/card_seting/editStatus',//修改卡类状态
    fenfaCard: 'son/card_seting/distributeCard',//分发卡类
    allCardList: 'son/card_seting/allCardList',//所以卡列表
    cardDiscountList: 'son/card_seting/cardDiscountList',//项目卡折扣列表
    createCardDiscount: 'son/card_seting/createCardDiscount',//创建项目卡折扣
    batchUpdateCardDiscount: 'son/card_seting/batchUpdateCardDiscount',//批量更新卡折扣
    discountDistributeCopy:'son/card_seting/distributeCopy',//折扣分发
    cardBatchSet:'son/card_seting/batchSet',//卡类批量设置

    listVoucher: 'son/voucher_seting/listVoucher',//抵用券列表
    addVoucher: 'son/voucher_seting/addVoucher',//新增抵用券
    editVoucher: 'son/voucher_seting/editVoucher',//编辑抵用券
    veditStatus: 'son/voucher_seting/editStatus',//修改抵用券状态

    listSecondCardSeting: 'son/project_second_card_seting/listSecondCardSeting',//项目次卡列表
    addSecondCardSeting: 'son/project_second_card_seting/addSecondCardSeting',//新增项目次卡
    editSecondCardSeting: 'son/project_second_card_seting/editSecondCardSeting',//编辑项目次卡
    ceditStatus: 'son/project_second_card_seting/editStatus',//修改项目次卡状态
    distributeSecondCard:'son/project_second_card_seting/distributeSecondCard',//分发项目次卡

    listQualificationCardSeting: 'son/qualification_card_seting/listQualificationCardSeting',//资格卡列表
    addQualificationCardSeting: 'son/qualification_card_seting/addQualificationCardSeting',//新增资格卡
    editQualificationCardSeting: 'son/qualification_card_seting/editQualificationCardSeting',//编辑资格卡
    qditStatus: 'son/qualification_card_seting/editStatus',//修改资格卡状态

    listPackageSeting: 'son/package_seting/listPackageSeting',//套餐资料列表
    addPackageSeting: 'son/package_seting/addPackageSeting',//新增套餐资料
    editPackageSeting: 'son/package_seting/editPackageSeting',//编辑套餐资料
    beditStatus: 'son/package_seting/editStatus',//修修改套餐资料状态

    listPerformanceCommission: 'son/performance_commission_seting/listPerformanceCommission',//业绩提成列表
    listPerformance: 'son/performance_commission_seting/listPerformance',//业绩提成名称列表
    addPerformanceCommission: 'son/performance_commission_seting/addPerformanceCommission',//新增业绩提成
    editPerformanceCommission: 'son/performance_commission_seting/editPerformanceCommission',//编辑业绩提成
    delPerformanceCommission: 'son/performance_commission_seting/delPerformanceCommission',//删除业绩提成
    deditStatus: 'son/performance_commission_seting/editStatus',//编辑业绩提成状态

    listSonPerformanceCommissionSeting: 'son/performance_commission_seting/listSonPerformanceCommissionSeting',//子方案列表
    listSonPerformance: 'son/performance_commission_seting/listSonPerformance',//子方案名称列表
    addSonPerformanceCommissionSeting: 'son/performance_commission_seting/addSonPerformanceCommissionSeting',//新增子提成方案
    editSonPerformanceCommissionSeting: 'son/performance_commission_seting/editSonPerformanceCommissionSeting',//编辑子提成方案
    editSonStatus: 'son/performance_commission_seting/editSonStatus',//修改子方案状态
    delSonPerformanceCommission: 'son/performance_commission_seting/delSonPerformanceCommission',//删除子提成方案

    addEquity: 'son/equity_seting/addEquity',//新增储值权益
    editEquity: 'son/equity_seting/editEquity',//编辑权益资料
    listEquity: 'son/equity_seting/listEquity',//权益资料列表
    qeditStatus: 'son/equity_seting/editStatus',//修改权益状态

    reArrive:'son/staff/reArrive',//员工再到职
    getUserByMobile:'son/user/getUserByMobile',//根据手机号获取用户信息
    stafflist: 'son/staff/stafflist',//员工列表
    StaffListManage: 'son/staff/StaffListManage',//员工管理列表
    staffadd: 'son/staff/staffadd',//添加员工
    staffedit: 'son/staff/staffedit',//员工修改
    staffdepart: 'son/staff/staffdepart',//员工离职
    staffdel: 'son/staff/staffdel',//员工删除
    getStaffNumber: 'son/Staff/getStaffNumber',//员工编号生成
    DepartList: 'son/Changes/DepartList',//离职列表
    Cstaffdel: 'son/Changes/staffdel',//员工删除
    Blacklist: 'son/Changes/Blacklist',//员工拉黑
    BlacklistList: 'son/Changes/BlacklistList',//黑名单列表
    BlacklistRestore: 'son/Changes/BlacklistRestore',//员工拉黑 恢复
    userPerformanceList: 'son/Count.User/userPerformanceList',//员工业绩排行
    storeJournal: 'son/Count.User/storeJournal',//日记账
    businessSummary:'son/Statistics/businessSummary',//营业汇总表


    SchedulingSetup: 'son/StaffScheduling/SchedulingSetup',//排班类型
    SchedulingSetupAdd: 'son/StaffScheduling/SchedulingSetupAdd',//添加排版类型
    SchedulingSetupEdit: 'son/StaffScheduling/SchedulingSetupEdit',//编辑排版类型
    Scheduling: 'son/StaffScheduling/Scheduling',//排班列表
    SchedulingAdd: 'son/StaffScheduling/SchedulingAdd',//添加排班
    SchedulingSetupDelQuery: 'son/StaffScheduling/SchedulingSetupDelQuery',//删除排班类型之前的查询
    SchedulingSetupDel: 'son/StaffScheduling/SchedulingSetupDel',//删除排班类型
    userPerformanceDetailList: 'son/Count.User/userPerformanceDetailList',//员工业绩明细表
    peopleCount:'son/ReportGenerator/peopleCount', //计算人数
    reSettle:'son/Achieve/reSettle', //重算业绩

    MemberList: 'son/Member/MemberList',//会员列表
    searchMember: 'son/Member/searchMember',//会员列表
    MemberAdd: 'son/Member/MemberAdd',//添加会员
    MemberEdit: 'son/Member/MemberEdit',//会员修改
    queryMember: 'son/Member/queryMember',//会员详情
    MemberInfo: 'son/Member/MemberInfo',//会员详情
    MemberDetail: 'son/Member/MemberDetail',//会员基本信息
    NotesEdit: 'son/Member/NotesEdit',//备注保存
    PasswordEdit: 'son/Member/PasswordEdit',//密码修改
    getMemberNumber: 'son/Member/getMemberNumber',//会员编号生成
    setUserCardZhu: 'son/Member/setUserCardZhu',//将会员卡设为主卡
    memberIntegralAdd: 'son/Member/memberIntegralAdd',//会员积分增加
    memberIntegradec: 'son/Member/memberIntegradec',//会员积分减少
    memberIntegraList: 'son/Member/memberIntegraList',//会员积分记录列表
    MemberFilesInfo: 'son/Member/MemberFilesInfo',//会员档案
    MemberFilesInfoAdd: 'son/Member/MemberFilesInfoAdd',//保存内容档案
    MemberFilesInfoList: 'son/member/MemberFilesInfoList',//会员档案内容列表
    MemberFilesInfoDel: 'son/member/MemberFilesInfoDel',//删除会员档案
    listFollowUp: 'son/member/listFollowUp',//回访记录列表
    addFollowUp: 'son/member/addFollowUp',//新增回访记录
    editFollowUp: 'son/member/editFollowUp',//修改回访记录
    delFollowUp: 'on/member/delFollowUp',//删除回访记录
    addServiceRegistration: 'son/member/addServiceRegistration',//新增服务登记
    listServiceRegistration: 'son/member/listServiceRegistration',//服务登记列表
    editServiceRegistration: 'son/member/editServiceRegistration',//修改服务登记
    memberProject: 'son/Member/memberProject',//消费项目列表
    transactionList: 'son/member/transactionList',//交易历史
    accountList: 'son/member/accountList',//账户历史列表
    agreementContentList: 'son/member/agreementContentList',//协议内容列表
    VipeditCard: 'son/SeaCard.OpenVipCard/editCard',//常规操作/换卡/修改卡资料/卡注销
    StoreReceiptMember: 'son/Inventory/StoreReceiptMember',//会员详情-欠货/寄存
    mergeCard: 'son/SeaCard.OpenVipCard/mergeCard',//并卡
    disassembleCard: 'son/SeaCard.OpenVipCard/disassembleCard',//拆卡
    convertProject: 'son/SeaCard.OpenVipCard/convertProject',//项目兑换
    LabelList: 'son/Label/LabelList',//维度列表
    LabelInfo: 'son/Label/LabelInfo',//维度详情
    LabelAdd: 'son/Label/LabelAdd',//添加维度
    LabelEdit: 'son/Label/LabelEdit',//编辑维度
    LabelDel: 'son/Label/LabelDel',//维度删除
    member_label: 'son/label/member_label',//会员添加标签
    getRootLabelCategory:'son/Label/getRootLabelCategory',//标签一级分类列表
    setLabelStatus:'son/Label/setStatus',//标签状态设置
    arrearsLog: 'son/SeaCard.OpenVipCard/arrearsLog',//欠款明细列表
    cardDetails: 'son/SeaCard.OpenVipCard/cardDetails',//会员卡详情
    addMemberConsumption: 'son/member/addMemberConsumption',//消费明细-补录
    listMemberConsumption: 'son/member/listMemberConsumption',//消费明细列表
    listChangesLog: 'son/SeaCard.OpenVipCard/listChangesLog',//异动历史列表
    extensionCardProject: 'son/SeaCard.OpenVipCard/extensionCardProject',//卡项目延期
    cardTransferCardProject: 'son/SeaCard.OpenVipCard/cardTransferCardProject',//卡项目转卡
    cancelCardProject: 'son/SeaCard.OpenVipCard/cancelCardProject',//卡项目作废
    restoreCardProject: 'son/SeaCard.OpenVipCard/restoreCardProject',//卡项目作废
    checkUserCard: 'son/SeaCard.OpenVipCard/checkUserCard',//校验卡升级
    cardRefund:'son/SeaCard.OpenVipCard/cardRefund', //会员退卡
    serviceRecord:'son/member/serviceRecord', //服务记录明细
    itemConvert:'son/member/itemConvert',//单笔项目兑换
    editAchieveUnitPrice:'son/member/editAchieveUnitPrice',//修改单笔业绩
    cardTransactionLog:'son/UserCard/cardTransactionLog',//修改单笔业绩
    couponRandom:'son/UserCard/couponRandom', //生成随机码
    changeCardUser:'son/UserCard/changeCardUser', //修改持卡人
    transferNewMember:'son/UserCard/transferNewMember', // 修改持卡人给新会员
    searchByMobile:'son/member/searchByMobile',//根据手机号查询会员
    getMemberLabel:'son/member/getMemberLabel',//得到会员标签
    memberPoint:'son/member/memberPoint',//会员积分值
    setOfficialSend:'son/Member/setOfficialSend',//设置公众号是否推送
    thirdHistory:'son/member/thirdHistory',//第三方交易历史

    sms_channel:'son/sms/sms_channel',//短信通道列表
    sms_store:'son/sms/sms_store',//门店短信剩余数量
    getBindStoreSmsCode:'son/sms/getBindStoreSmsCode',//绑定门店发送短信
    mobile_in_store:'son/sms/mobile_in_store',//所有会员数量
    clearImportMobile:'son/sms/clearImportMobile',//清空导入手机号
    addSmsMobile:'son/sms/addSmsMobile',//群发短信导入
    smsSendLogList:'son/sms/sendLogList',//短信发送记录
    group_send:'son/sms/group_send',//群发短信
    getSmsStoreSign:'son/sms/getSmsStoreSign', //得到门店短信签名
    getSendMobileCount:'son/sms/getSendMobileCount',//发送短信的数量


    EnterList: 'son/Inventory/EnterList',//产品入库列表
    EnterAdd: 'son/Inventory/EnterAdd',//产品入库添加
    InventoryInfo: 'son/Inventory/InventoryInfo',//出入库详情
    EnterEdit: 'son/Inventory/EnterEdit',//产品入库修改
    InventoryExamine: 'son/Inventory/InventoryExamine',//出入库审核
    InventoryDel: 'son/Inventory/InventoryDel',//出入库删除
    InventoryRevokeExamine: 'son/Inventory/InventoryRevokeExamine',//出入库 撤销审核
    LeaveList: 'son/Inventory/LeaveList',//产品出库列表
    InventoryRefuse: 'son/Inventory/InventoryRefuse',//出入库拒绝
    InventoryCancel: 'son/Inventory/InventoryCancel',//出入库作废
    LeaveProduct: 'son/Inventory/LeaveProduct',//产品出库添加-产品列表
    LeaveAdd: 'son/Inventory/LeaveAdd',//产品出库添加
    LeaveEdit: 'son/Inventory/LeaveEdit',//产品出库修改
    CopyList: 'son/Inventory/CopyList',//库存盘点列表
    CopyAdd: 'son/Inventory/CopyAdd',//库存盘点添加
    CopyEdit: 'son/Inventory/CopyEdit',//库存盘点修改
    CopyInfo: 'son/Inventory/CopyInfo',//盘点详情
    Reconciliation: 'son/Inventory/Reconciliation',//盘点-一键调账
    StoreReceipt: 'son/Inventory/StoreReceipt',//门店收货列表
    StoreReceiptAdd: 'son/Inventory/StoreReceiptAdd',//门店收货-添加寄存
    StoreReceiptDepositList: 'son/Inventory/StoreReceiptDepositList',//门店收货-详情-寄存列表
    StoreReceiptAccessList: 'son/Inventory/StoreReceiptAccessList',//门店收货-详情-存取列表
    StoreReceiptInfo: 'son/Inventory/StoreReceiptInfo',//门店收货-详情
    StoreReceiptDeposit: 'son/Inventory/StoreReceiptDeposit',//门店收货-寄存
    StoreReceiptAccessAdd: 'son/Inventory/StoreReceiptAccessAdd',//门店收货-详情-领取
    StoreReceiptAccessDel: 'son/Inventory/StoreReceiptAccessDel',//门店收货-详情-删除
    getInventoryNumber: 'son/Inventory/getInventoryNumber',//出入库编号生成
    getCheckNumber: 'son/Inventory/getCheckNumber',//盘点编号生成
    getDepositNumber: 'son/Inventory/getDepositNumber',//门店收货-详情-寄存编号生成

    getPaymentMethod: 'son/SeaCard.PaymentMethod/getPaymentMethod',//获取当前门店可支付方式
    getCardNo: 'son/SeaCard.GetVipCardNo/getCardNo',//获取最新卡号
    addVipCard: 'son/SeaCard.OpenVipCard/addVipCard',//会员开卡
    getUserCardList: 'son/SeaCard.OpenVipCard/getUserCardList',//获取会员卡列表
    selectCardList: 'son/SeaCard.OpenVipCard/selectCardList',//获取会员卡列表
    storedValueUserCard: 'son/SeaCard.OpenVipCard/storedValueUserCard',//会员卡充值/充项目
    getOrderNo: 'son/SeaCard.GetVipCardNo/getOrderNo',//获取最新订单号
    cardCashierLogList: 'son/SeaCard.OpenVipCard/cardCashierLogList',//卖卡单据列表
    cardCashierLogdetails: 'son/SeaCard.OpenVipCard/cardCashierLogdetails',//卖卡充值详情
    cancelCashierLog: 'son/SeaCard.OpenVipCard/cancelCashierLog',//卖卡充值作废
    hideZeroCard:'son/SeaCard.OpenVipCard/hideZeroCard',//屏蔽权益为0的会员卡

    addFlowCard:'son/flow/addFlowCard',//新增流水牌
    flowCardList:'son/flow/flowCardList',//流水牌列表
    flowCardInfo:'son/flow/flowCardInfo', //流水牌详情
    editFlowCard:'son/flow/editFlowCard', //编辑流水牌
    saveFlowStaff:'son/flow/saveFlowStaff', //流水牌员工设置
    staff:'son/flow/staff', //流水牌员工
    flowStaffList:'son/flow/flowStaffList',//流水牌员工设置列表
    flowCardSort:'son/flow/flowCardSort', //拖动排序
    flowIndex:'son/flow/flowIndex', //流水牌大屏
    setStatus:'son/flow/setStatus',//流水牌大屏设置状态
    getStaffService:'son/flow/getStaffService',//流水牌大屏刷新正在服务
    flowSort:'son/flow/flowSort',//流水牌大屏排序
    setStaffIdle:'son/flow/setStaffIdle', //流水牌大屏设置员工空闲
    setStaffWork:'son/flow/setStaffWork',//流水牌大屏设置员工服务中
    flowStaffInfo:'son/flow/flowStaffInfo', //流水牌大屏员工详情

    recentConsumer:'son/consumer/recentConsumer',//最近消费日期
    getYearMonthSum:'son/ConsumerCashier.ConsumerCashierNo/getYearMonthSum',//年卡每月使用次数
    delPutUp:'son/ConsumerCashier.ConsumerCashierNo/delPutUp',//删除挂单数据
    putUpCashier:'son/ConsumerCashier.ConsumerCashierNo/putUpCashier', //获取挂单数据
    getConsumerCashierNo: 'son/ConsumerCashier.ConsumerCashierNo/getConsumerCashierNo',//获取最新订单号
    addConsumerCashierNo: 'son/ConsumerCashier.ConsumerCashierNo/addConsumerCashierNo',//新增消费收银
    listConsumerCashierNo: 'son/ConsumerCashier.ConsumerCashierNo/listConsumerCashierNo',//消费收银单据列表
    infoConsumerCashierNo: 'son/ConsumerCashier.ConsumerCashierNo/infoConsumerCashierNo',//消费收银单据详情(新)
    releaseCashier: 'son/ConsumerCashier.ConsumerCashierNo/releaseCashier',//解除收银
    rePutUp: 'son/ConsumerCashier.ConsumerCashierNo/rePutUp',//重新挂单
    reConsumer: 'son/ConsumerCashier.ConsumerCashierNo/reConsumer',//修改单据重新保存
    FinanceRewardsList: 'son/FinanceRewards/FinanceRewardsList',//奖惩登记表列表
    FinanceRewardsInfo: 'son/FinanceRewards/FinanceRewardsInfo',//登记表详情
    FinanceRewardsDel: 'son/FinanceRewards/FinanceRewardsDel',//登记表删除
    FinanceRewardsAdd: 'son/FinanceRewards/FinanceRewardsAdd',//登记表添加
    FinanceRewardsEdit: 'son/FinanceRewards/FinanceRewardsEdit',//登记表修改
    FgetMemberNumber: 'son/FinanceRewards/getMemberNumber',//登记表编号生成
    MgetMemberNumber: 'son/FinanceExpenses/getMemberNumber',//编号生成
    FinanceExpensesList: 'son/FinanceExpenses/FinanceExpensesList',//收入支出登记列表
    FinanceExpensesInfo: 'son/FinanceExpenses/FinanceExpensesInfo',//详情
    FinanceExpensesAgree: 'son/FinanceExpenses/FinanceExpensesAgree',//审核通过
    FinanceExpensesRefuse: 'son/FinanceExpenses/FinanceExpensesRefuse',//审核拒绝
    FinanceExpensesCancel: 'son/FinanceExpenses/FinanceExpensesCancel',//审核作废
    FinanceExpensesAdd: 'son/FinanceExpenses/FinanceExpensesAdd',//添加
    FinanceExpensesEdit: 'son/FinanceExpenses/FinanceExpensesEdit',//修改
    OperationList: 'son/Operation/OperationList',//操作历史

    MakeTypeList: 'son/make/MakeTypeList',//预约类型列表
    MakeTypeSave: 'son/make/MakeTypeSave',//预约类型保存
    MakeList: 'son/make/MakeList',//预约列表
    MakeAdd: 'son/make/MakeAdd',//预约-添加
    MakeEdit: 'son/make/MakeEdit',//预约-编辑
    MakeInfo: 'son/make/MakeInfo',//预约详情
    MakeArrival: 'son/make/MakeArrival',//预约-到店
    MakeCancel: 'son/make/MakeCancel',//预约-取消预约
    MakeSoon: 'son/make/MakeSoon',//即将到店
    StaffList: 'son/make/StaffList',//预约-员工设置-员工列表
    MakeChart: 'son/make/MakeChart',//预约图表
    MakeOccupy: 'son/make/MakeOccupy',//预约-占用
    StaffInstall: 'son/make/StaffInstall',//预约-员工设置-保存
    getMakeConfig:'son/make/getMakeConfig',//预约-员工设置-右侧配置
    setMakeTime:'son/make/setMakeTime', //预约时间保存
    getMakeTime:'son/make/getMakeTime',//预约时间显示
    makeUserStatus:'son/make/makeUserStatus',//预约员工状态设置
    updateMakerNumber:'son/make/updateMakerNumber',//修改可预约数
    updateSort:'son/make/updateSort',//员工排序上移下移
    broadSet:'son/make/broadSet',//看板设置
    broadInfo:'son/make/broadInfo',//看板设置反显
    getCoverTime:'son/make/getCoverTime',//图表员工设置覆盖区间
    MakeUnArrival:'son/make/MakeUnArrival',//预约-未到店

    PaymentCenterList: 'son/PaymentCenter/PaymentCenterList',//付费中心


    listMenu: 'son/menu/listMenu',
    addMenu: 'api/menu/addMenu',
    delMenu: 'api/menu/delMenu',
    editMenu: 'api/menu/editMenu',

    GoodsCategorySave: 'son/Goods/GoodsCategorySave', //商品分类保存
    GoodsCategory: 'son/Goods/GoodsCategory', //商品分类列表
    GoodsCategoryDel: 'son/Goods/GoodsCategoryDel', // 商品分类删除

    GoodsList: 'son/Goods/GoodsList', // 商品列表
    GroundingGoods: 'son/Goods/GroundingGoods', // 商品列表-商品上架
    undercarriageGoods: 'son/Goods/undercarriageGoods', // 商品列表-商品下架
    GoodsSave: 'son/Goods/GoodsSave', // 商品列表-商品保存
    GoodsInfo: 'son/Goods/GoodsInfo', // 商品列表-商品详情

    GoodsFlashSaleIndex: 'son/GoodsFlashSale/index', // 秒杀商品列表
    grounding: 'son/GoodsFlashSale/grounding', // 秒杀商品列表-秒杀商品上架
    Saleundercarriage: 'son/GoodsFlashSale/undercarriage', // 秒杀商品列表-秒杀商品下架
    GoodsFlashSaleSave: 'son/GoodsFlashSale/GoodsFlashSaleSave', // 秒杀商品列表-秒杀商品保存
    GoodsFlashSaleShow: 'son/GoodsFlashSale/GoodsFlashSaleShow', // 秒杀商品列表-秒杀商品详情

    GoodsChoose: 'son/Goods/choose', // 添加拼团和秒杀时的商品列表

    GoodsPinTuanIndex: 'son/GoodsPinTuan/index', // 拼团商品
    GoodsPinTuanGrounding: 'son/GoodsPinTuan/grounding', // 拼团商品上架
    GoodsPinTuanUndercarriage: 'son/GoodsPinTuan/undercarriage', // 拼团商品下架
    GoodsPinTuanSave: 'son/GoodsPinTuan/GoodsPinTuanSave', // 拼团商品保存
    GoodsPinTuanShow: 'son/GoodsPinTuan/GoodsPinTuanShow', // 拼团商品详情

    GoodsPinTuanGroup: 'son/GoodsPinTuanGroup/index', // 拼团管理
    getGroupMemberList: 'son/GoodsPinTuanGroup/getGroupMemberList', // 查看
    getGroupDetail: 'son/GoodsPinTuanGroup/getGroupDetail', // 拼团管理
    toGroup: 'son/GoodsPinTuanGroup/toGroup', // 拼团管理

    FreightTemplateSave: 'son/GoodsFreightTemplate/FreightTemplateSave', // 运费模板保存
    FreightTemplateShow: 'son/GoodsFreightTemplate/FreightTemplateShow', // 运费模板详情

    IndexList: 'son/GoodsIndex/IndexList',
    IndexAdd: 'son/GoodsIndex/IndexAdd',
    IndeDel: 'son/GoodsIndex/IndeDel',
    IndexEdit: 'son/GoodsIndex/IndexEdit',
    BatchUpdateState: 'son/GoodsIndex/BatchUpdateState',
    UpdateState: 'son/GoodsIndex/UpdateState',
    IndexPageList: 'son/GoodsIndex/IndexPageList',
    UpdateSort: 'son/GoodsIndex/UpdateSort',

    GoodsOrderIndex: 'son/GoodsOrder/index', // 订单列表
    GoodsOrderDetail: 'son/GoodsOrder/detail',
    deliverGoods: 'son/GoodsOrder/deliverGoods', // 可以发货
    overGoods: 'son/GoodsOrder/overGoods', // 订单完成 状态=3待收货 可以完成
    writeOff: 'son/GoodsOrder/writeOff',  // 订单核销 状态=4 可以核销

    SmsTemplateList: 'son/sms/SmsTemplateList', //短信模版列表
    SmsTemplateSave: 'son/sms/SmsTemplateSave', //短信模版保存
    payLogList: 'son/sms/payLogList', //短信购买记录
    sendLogList: 'son/sms/sendLogList', //短信发送记录
    saveConfig: 'son/sms/saveConfig', //保存配置信息
    smsPackageList: 'api/sms/smsPackageList', //套餐列表
    paySmsOrder: 'son/sms/paySmsOrder', //短信条数充值下单

    MemberKeqinSetup: 'son/staff/MemberKeqinSetup', //客勤汇总
    MemberKeqinSetupList: 'son/staff/MemberKeqinSetupList', //客勤列表
    MemberKeqinSetupFenpei: 'son/staff/MemberKeqinSetupFenpei', //客勤分配
    listWithdrawlog: 'son/shop_seting/listWithdrawlog', //提现记录列表
    applyWithdrawal: 'son/goods_order/applyWithdrawal', //申请提现
    saveWithdrawStatus: 'son/shop_seting/saveWithdrawStatus', //更改提现状态
    consumption: 'son/ReportGenerator/consumption', //消费明细表
    smsConfigDetails: 'son/sms/smsConfigDetails', //短信配置详情

    ReportList: 'son/DynamicReport/ReportList', //门店菜单显示用动态SQL
    GetReportData: 'son/DynamicReport/GetReportData', //动态SQL查询列表
    SmsTemplateTypeList: 'son/sms/SmsTemplateTypeList', //短信类型列表
    meetingBoard: 'son/ReportGenerator/meetingBoard', //会议看板
    memberTransform: 'son/ReportGenerator/memberTransform', //会员转化分析
    projectSale: 'son/ReportGenerator/projectSale', //项目销售消耗
    charge: 'son/ReportGenerator/charge', //日记账明细
    performanceDetailList: 'son/ReportGenerator/performanceDetailList', //员工业绩明细表
    NewuserPerformanceList: 'son/ReportGenerator/userPerformanceList', //员工业绩排行榜
    salaryList: 'son/ReportGenerator/salaryList', //员工工资汇总
    departmentalPerformance: 'son/ReportGenerator/departmentalPerformance', //部门业绩分析
    cardSale: 'son/ReportGenerator/cardSale', //会员卡销售统计
    userStaffAttendanceList: 'son/WorkAttendance/userStaffAttendanceList', //员工考勤
    memberAnalysis:'son/ReportGenerator/memberAnalysis',//会员资料统计
    projectAnalysis:'son/ReportGenerator/projectAnalysis',//会员品项统计

}
export {
    Config
};